import { onMounted, onUnmounted, ref } from 'vue';

export default function useWindowResize(
  callback: (width: number, height: number) => void
) {
  const width = ref(0);
  const height = ref(0);

  function onResize() {
    width.value = window.innerWidth;
    height.value = window.innerHeight;

    callback && callback(width.value, height.value);
  }

  onMounted(() => {
    window.addEventListener('resize', onResize);
    onResize();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onResize);
  });

  return {
    width,
    height,
  };
}
