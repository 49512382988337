/**
 * Generate from url: https://localhost:44305/swagger/v1/swagger.json
 * It is recommended not to modify the document
 * Total count: 1
 **/
import type { SendSecurityCodeInput } from './types';
import { api } from 'boot/axios';

/**
 * *TODO* POST /api/app/security/send-security-code
 **/
export function sendSecurityCode(payload: SendSecurityCodeInput) {
  return api.post<void>('/api/app/security/send-security-code', {
    ...payload,
  });
}

export default {
  sendSecurityCode,
};
