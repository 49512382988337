/**
 * Generate from url: https://localhost:44305/swagger/v1/swagger.json
 * It is recommended not to modify the document
 * Total count: 5
 **/
import type {
  MessageCreateOrUpdateDto,
  MessageDto,
  PagedResultDto,
} from './types';
import { api } from 'boot/axios';

/**
 * *TODO* POST /api/app/message
 **/
export function create(payload: MessageCreateOrUpdateDto) {
  return api.post<MessageDto>('/api/app/message', {
    ...payload,
  });
}

/**
 * *TODO* GET /api/app/message/{id}
 **/
export function get(id: string) {
  return api.get<MessageDto>(`/api/app/message/${id}`, {});
}

/**
 * *TODO* GET /api/app/message
 **/
export function getList(params: {
  filter?: string | undefined;
  sorting?: string | undefined;
  skipCount?: number | undefined;
  maxResultCount?: number | undefined;
}) {
  return api.get<PagedResultDto<MessageDto>>('/api/app/message', {
    params: params,
  });
}

/**
 * *TODO* DELETE /api/app/message/{id}
 **/
export function remove(id: string) {
  return api.delete<void>(`/api/app/message/${id}`, {});
}

/**
 * *TODO* PUT /api/app/message/{id}
 **/
export function update(id: string, payload: MessageCreateOrUpdateDto) {
  return api.put<MessageDto>(`/api/app/message/${id}`, {
    ...payload,
  });
}

export default {
  create,
  get,
  getList,
  remove,
  update,
};
