import script from "./SvCarousel.vue?vue&type=script&setup=true&lang=ts"
export * from "./SvCarousel.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import qInstall from "../../node_modules/.pnpm/@quasar+app-webpack@3.13.2_eslint@8.57.0_pinia@2.1.7_quasar@2.16.2_vue-router@4.3.2_vue@3.4.27/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCarousel,QCarouselSlide});
