import { defineStore } from 'pinia';
import { getAll, get, find } from 'src/api/Page';
import { PageDto } from 'src/api/types';
import { listToTree } from 'src/utils/tree';
import { filterIsActive } from 'src/utils/fetch';

export interface PageWithChildrenDto extends PageDto {
  children?: PageWithChildrenDto[];
}

interface PageStoreState {
  menus: any[];
  pages: Record<string, any>;
  page: any;
}

export const usePageStore = defineStore('page', {
  state: (): PageStoreState => ({
    menus: [],
    pages: {},
    page: {},
  }),
  actions: {
    async fetchAll() {
      const { data } = await getAll({ sorting: 'seq ascending' });
      if (data.items) {
        this.menus = listToTree<PageWithChildrenDto>(
          data.items.filter((t) => t.isActive),
          {
            pid: 'parentId',
          }
        );
        this.menus = this.menus.filter((t) => !t.parentId);
      } else {
        this.menus = [];
      }
    },
    async fetch(params: { id?: string; url?: string }) {
      const { data } = params.id
        ? await get(params.id)
        : await find(params.url ?? '/');

      filterIsActive(data);

      this.pages[params.id ?? params.url ?? '/'] = data;
      this.page = data;
    },
  },
});


