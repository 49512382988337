/**
 * Generate from url: https://localhost:44305/swagger/v1/swagger.json
 * It is recommended not to modify the document
 * Total count: 9
 **/
import type {
  PageCreateOrUpdateDto,
  PageWithRegionsDto,
  ListResultDto,
  PageDto,
  PagedResultDto,
  SortDto,
} from './types';
import { api } from 'boot/axios';

/**
 * *TODO* GET /api/app/page/find
 **/
export function find(url: string) {
  return api.get<PageWithRegionsDto>('/api/app/page/find', {
    params: {
      url,
      deviceType: 'pc'
    },
  });
}

/**
 * *TODO* PATCH /api/app/page/{id}/active
 **/
export function active(id: string) {
  return api.patch<void>(`/api/app/page/${id}/active`, {});
}

/**
 * *TODO* POST /api/app/page
 **/
export function create(payload: PageCreateOrUpdateDto) {
  return api.post<PageWithRegionsDto>('/api/app/page', {
    data: payload,
  });
}

/**
 * *TODO* PATCH /api/app/page/{id}/disactive
 **/
export function disactive(id: string) {
  return api.patch<void>(`/api/app/page/${id}/disactive`, {});
}

/**
 * *TODO* GET /api/app/page/{id}
 **/
export function get(id: string) {
  // return api.get<PageWithRegionsDto>(`/api/app/page/${id}`, {});
  return api.get<PageWithRegionsDto>('/api/app/page/find-entity', {
    params: {
      entityid: id,
      deviceType: 'pc'
    },
  });
}

/**
 * *TODO* GET /api/app/page/all
 **/
export function getAll(params: { sorting?: string | undefined }) {
  return api.get<ListResultDto<PageDto>>('/api/app/page/all', {
    params: {
      sorting: params.sorting,
      deviceType: 'pc'
    },
  });
}

/**
 * *TODO* GET /api/app/page
 **/
export function getList(params: {
  parentId?: string | undefined;
  deviceType?: 'pc';
  filter?: string | undefined;
  sorting?: string | undefined;
  skipCount?: number | undefined;
  maxResultCount?: number | undefined;
}) {
  return api.get<PagedResultDto<PageDto>>('/api/app/page', {
    params: params,
  });
}

/**
 * *TODO* DELETE /api/app/page/{id}
 **/
export function remove(id: string) {
  return api.delete<void>(`/api/app/page/${id}`, {});
}

/**
 * *TODO* POST /api/app/page/sort
 **/
export function sort(payload: SortDto) {
  return api.post<void>('/api/app/page/sort', {
    data: payload,
  });
}

/**
 * *TODO* PUT /api/app/page/{id}
 **/
export function update(id: string, payload: PageCreateOrUpdateDto) {
  return api.put<PageWithRegionsDto>(`/api/app/page/${id}`, {
    data: payload,
  });
}

export default {
  active,
  create,
  disactive,
  get,
  getAll,
  getList,
  remove,
  sort,
  update
};
